import React, {useEffect, useState, useContext} from 'react'
import Main from '../components/Main'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useHistory, Link } from 'react-router-dom'
import {
  REST_BECOME_CUSTOMER,
  REST_CUSTOMER_PROFILE,
  REST_CUSTOMER_GET_STORES, // returns stores in the city of customer
  REST_CUSTOMER_GET_ALL_POINTS,
  requestToServer,
  getLocalData
} from '../lib/helper'
import {UI_COLOR_BUTTON} from '../config'


import { GeneralContext } from '../lib/context'

import { Modal, List, Row, Col } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'

export default function Comerces() {

  const [state, dispatch] = useContext(GeneralContext)

  const [cityName, setCityName] = useState(null) // the city of the customer
  const [cityStores, setCityStores] = useState([])
  const [selectedStore, setSelectedStore] = useState(null)

  const [listLoading, setListLoading] = useState(true)

  let history = useHistory()

  useEffect(() => {
    let localData = getLocalData()

    if ( localData ) {
      requestToServer({},REST_CUSTOMER_GET_ALL_POINTS).then( result =>{
        if ( result.s === 'ok' ) {
          dispatch({
            type: 'SET_USER_STORES',
            payload: result.storePoints
          })
          setListLoading(false)
        }
      })

      requestToServer({},REST_CUSTOMER_GET_STORES).then( result => {
        if (result.s === 'ok' && result.cityStores ) {
          setCityStores(result.cityStores)
        }
      })

      requestToServer({},REST_CUSTOMER_PROFILE).then( ({profile}) => dispatch({type: 'SET_PROFILE', payload: profile}) )

    } else {
      history.push('/')
    }
    
  }, [])
    
  useEffect(()=>{
    if ( state && state.profile && state.profile.city ) { setCityName( state.profile.city.name ) }
  }, [state])


  return (
    <Main>

      <Header title='Comercios' backLink='/' />

      <List
        dataSource={state.userStores}
        renderItem={item => (
          <div style={{borderWidth:'2px',borderColor:'#cccccc', padding:'10px', margin:'10px', borderStyle:'solid', borderRadius:'5px'}} className='clickable'>
            <Row  key={item.id} onClick={()=> history.push('/comercio/' + item.id) }>
              <Col span="19">
                {item.logo && <div style={{float:'left', paddingRight:'10px'}}><img src={item.logo} style={{width:'70px', height:'auto'}}/></div> }
                <div style={{fontWeight:'bold', fontSize:'1.2em'}}>{item.name}</div>
                <div>{item.storeType}</div>
              </Col>
              <Col span="5" style={{textAlign:'center'}}>
                <div style={{fontWeight:'bold', fontSize:'1.3em', lineHeight:'120%'}}>{item.purchaseAmount - item.redeemedAmount}</div>
                <div>puntos</div>
              </Col>
            </Row>
          </div>
        )}
        loading={ listLoading && state.userStores.length === 0 }
        locale={{emptyText:'Aún no eres cliente de algún comercio.'}}
      />

      { cityName ? <div>
          <h3 style={{paddingTop:'10px',paddingLeft:'15px'}}>Comercios en <strong>{cityName}</strong></h3>
          <List
            dataSource={cityStores}
            locale={{emptyText: 'No se tienen más comercios'}}
            renderItem={item => (
              <div  style={{borderWidth:'2px',borderColor:'#cccccc', padding:'10px', margin:'10px', borderStyle:'solid', borderRadius:'5px'}} className='clickable'>
                <Row  key={item.id} onClick={()=> setSelectedStore(item) }>
                  <Col span="19">
                    <div style={{fontWeight:'bold', fontSize:'1.2em'}}>{item.name}</div>
                    <div>{item.storetype.name}</div>
                  </Col>
                  <Col span="5" style={{textAlign:'center', paddingTop:'10px'}}>
                    <ArrowRightOutlined style={{fontSize:'25px', color:UI_COLOR_BUTTON}}/>
                  </Col>
                </Row>
              </div>
            )}
          />
        </div> :
        <div style={{textAlign:'center'}}>
        selecciona una ciudad en <Link to='/mi-perfil?r=/comercios'>tu perfil</Link>
      </div>}
      
      <Footer />

      <Modal
        visible={selectedStore}
        onCancel={()=>setSelectedStore(null)}
        onOk={()=>{
          requestToServer({storeId:selectedStore.id},REST_BECOME_CUSTOMER).then( result => {
            requestToServer({},REST_CUSTOMER_GET_ALL_POINTS).then( result =>{
              if ( result.s === 'ok' ) {
                dispatch({
                  type: 'SET_USER_STORES',
                  payload: result.storePoints
                })
                setListLoading(false)
              }
            })

            requestToServer({},REST_CUSTOMER_GET_STORES).then( result => {
              if (result.s === 'ok' && result.cityStores ) {
                setCityStores(result.cityStores)
              }
            })

          })
          setSelectedStore(null)
          
        }}
        okText={'CONFIRMAR'}
        cancelText={'Cerrar'}
        title = 'Cliente'
      >
        <div style={{fontSize:'1.1em'}}>
          <p>Eres cliente o deseas serlo del comercio <strong>{selectedStore ? selectedStore.name : ''}</strong>?</p>
          {selectedStore && selectedStore.welcomePoints && <p>
            Este comercio da <strong>{selectedStore.welcomePoints}</strong> puntos a cada nuevo cliente.
            </p>}
        </div>
      </Modal>

    </Main>
   

  )
}