import axios from 'axios';

import { API_SERVER } from '../config'

let localData = null

export const REST_CUSTOMER_REGISTER = API_SERVER + '/login/customer/register'
export const REST_CUSTOMER_LOGIN  =   API_SERVER + '/login/customer'

export const REST_PASSWORD_RECOVERY = API_SERVER + '/login/recovery-password'
export const REST_GET_ALLCOUNTRIES =  API_SERVER + '/general/get-countries'

export const REST_CUSTOMER_GET_STORES = API_SERVER + '/customer/store/get-all' // replace /customer/get-all-points
export const REST_CUSTOMER_GET_ALL_POINTS = API_SERVER + '/customer/get-all-points'
export const REST_CUSTOMER_UPDATE_PROFILE = API_SERVER + '/customer/update-profile'
export const REST_CUSTOMER_PROFILE = API_SERVER + '/customer/profile'
export const REST_DELETE_ME = API_SERVER + '/customer/delete-me'

export const REST_CHECK_CLIENTCODE_EMAIL = API_SERVER + '/login/customer/check-clientcode-email'
export const REST_REGISTER_EXISTENT = API_SERVER + '/login/customer/register-existent'

export const REST_REQUEST_REWARD = API_SERVER + '/customer/request-reward'

export const REST_OFFERS_GET_ALL = API_SERVER + '/customer/get-offers'
export const REST_OFFERS_GET_HOME = API_SERVER + '/customer/get-home-offers'

export const REST_GET_STORE_INFO        = API_SERVER + '/customer/store/info'
export const REST_GET_GENERAL_STORE_INFO = API_SERVER + '/general/store/info' // just public info
export const REST_SEND_DEVICE_TOKEN     = API_SERVER + '/customer/device/set-token' // returns customer info
export const REST_DELETE_DEVICE_TOKEN   = API_SERVER + '/customer/device/delete-token'

export const REST_BECOME_CUSTOMER           = API_SERVER + '/customer/store/become-customer'

export const REST_VALIDATE_PRE_REGISTRATION = API_SERVER + '/login/customer/validate-pre-registration'
export const REST_CONFIRM_PRE_REGISTRATION  = API_SERVER + '/login/customer/confirm-registration'

export const REST_PROCESS_PASS_RECOVER     = API_SERVER + '/general/process-password-recovery'
export const REST_VALIDATE_SURVEY          = API_SERVER + '/general/survey/validate'
export const REST_SAVE_SURVEY              = API_SERVER + '/general/survey/save'

export const ECHO_BACKEND = API_SERVER + '/general/echo'
export const REPORT_STAT_SERVER = 'https://report.pretii.lat'



export function requestToServer( bodyData, path ) {

  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  if ( localData && localData.token ) {
    headers.Token = localData.token
  }

  return new Promise((resolve, reject) => {

    axios.post(path, bodyData, {headers} ).then(r => {
      if (r.data){
        resolve(r.data)
      } else { console.log('ERROR') }
    }).catch(e => console.log('ERROR', e) )

  })
}

export function storeLocalData(data) {
  localData = data
  localStorage.removeItem('localData')
  localStorage.setItem('localData', JSON.stringify(localData) )
}

export function getLocalData() {

  if (localData) {
    return localData
  } else {
    let dataCookie = localStorage.getItem('localData')
    if ( dataCookie ) {
      localData = JSON.parse(dataCookie)
      return localData
    } else {
      return null
    }
  }
}

export function clearLocalData() {
  localData = null
  localStorage.removeItem('localData')
}


export const storeLocal = ({name, data}) => {
  if (name !== 'localData') {
    localStorage.setItem(name, JSON.stringify(data) )
  }
}
export const getStoredLocal = (name) => {
  let theData = localStorage.getItem(name)
  if ( theData ) {
    return ( JSON.parse(theData) )
  } else {
    return null
  }
}



export const getUrlParm = (param) => {
  let urlParams = new URLSearchParams(window.location.search)
  return ( urlParams.get(param) )
}
export const getUrlHashtag = () => {
  let hashTag = window.location.hash ? window.location.hash.substring(1) : null
  return ( hashTag )
}