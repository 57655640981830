import React from 'react'

import { Layout } from 'antd'
import 'antd/dist/antd.css'

const Main = ({ children }) => {
  return (
    <Layout>
      <Layout.Content style={{maxWidth:'600px', marginLeft:'auto', marginRight:'auto', width:'100%'}} className='main-content'>
        {children}
      </Layout.Content>
    </Layout>
  )
}

export default Main
