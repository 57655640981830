import React, {useEffect, useState, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Modal, Row, Col, Spin } from 'antd'

import Main from '../components/Main'

import LoginForm from '../components/LoginForm'
import HomeButtons from '../components/HomeButtons'
import Footer from '../components/Footer'
import Header from '../components/Header'
import AppButtons from '../components/AppButtons'


import {
  REST_OFFERS_GET_ALL,
  requestToServer,
  getLocalData, clearLocalData
} from '../lib/helper'
import { GeneralContext } from '../lib/context'
import { isMultiStore, UI_COLOR_BUTTON, versionName, UI_LOGIN_FOOTER_OBJ, clienAppName } from '../config'

export default function Home() {

  let history = useHistory()

  const [state, dispatch] = useContext(GeneralContext)

  const [isLogged, setIsLogged] = useState(false)

  const [offerModal, setOfferModal] = useState(null)
  const [gotOffers, setGotOffers] = useState(false) // to know if offes was get or not

  useEffect(() => {
    let localData = getLocalData()
    setIsLogged( localData ? true : false )


    if (localData) {
      getOffers() 
    }

    // spining offers
    if (state.offers && state.offers.length > 0) { setGotOffers(true) }

  }, [])

  const getOffers=()=>{
    requestToServer({},REST_OFFERS_GET_ALL).then( result => {
      if (result.s === 'ok') {
        setGotOffers(true)
        dispatch({type: 'SET_OFFERS', payload: result.offers})
      }
    })
  }


  return (
    <Main>

      { isLogged && <Header title={clienAppName}/> }

      { isLogged ? <HomeButtons doLogout={() => {
        dispatch({type: 'CLEAR_STATE'})
        clearLocalData()
        setIsLogged(false)
      }} /> :
          <LoginForm doAfterLogin={ () => {
            setIsLogged(true)
            getOffers()

            // redirecting to a page
            let urlParams = new URLSearchParams(window.location.search)
            let redirect = urlParams.get('redirect')
            if (redirect) { history.push(redirect)}

          }}/>
      }

      { isLogged && <AppButtons/> }

      { isLogged && <div className='home-offers'>
        <h2>Novedades</h2>
        <div style={{textAlign:'center'}}><Spin spinning={!gotOffers}/></div>
        { state && state.offers && <Row >
          {state.offers.map(item => <Col xs={12} sm={8} key={'offer' + item['offers.id']}>
            <div className='inner clickable' onClick={()=>setOfferModal(item) } >
              <div><img src={item['offers.imageSquared']} /></div>
              { isMultiStore ? <div>
              <h3>{item.name}</h3>
              <h4>{item['storetype.name']}</h4></div>
              : <h3>{item['offers.name']}</h3> }
            </div>
          </Col>)}
        </Row>}
        { getOffers && state && state.offers && state.offers.length === 0 && <div
            style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}
          >Aquí verás las novedades de tus comercios preferidos!</div>}

      </div>}

      { isLogged && <div style={{textAlign:'center', fontSize:'.7em', paddingTop:'20px'}}>versión {versionName}</div> }

      { isLogged ? <Footer /> : <div style={{textAlign:'center', paddingBottom:'30px'}}>
        { UI_LOGIN_FOOTER_OBJ.link ? <a href={UI_LOGIN_FOOTER_OBJ.link} target="_blank" rel="noreferrer">{UI_LOGIN_FOOTER_OBJ.text}</a> : <span>{UI_LOGIN_FOOTER_OBJ.text}</span>}
      </div> }

      <Modal
        visible={offerModal}
        onCancel={()=>setOfferModal(false)}
        okButtonProps={{ style: { display: 'none' } }}
        destroyOnClose={true}
        cancelText={'Cerrar'}
      >

        {offerModal && <div className='offer-popup'>
          <h3>{offerModal['offers.name']}</h3>
          { offerModal['offers.description'].split('\n').map((line, i) => (
              <p key={i}>
                {line}
              </p>
          ))}
          
          { isMultiStore && <div style={{textAlign:'center'}}>

            <Button size='large' className='standard-button' style={{backgroundColor:UI_COLOR_BUTTON, color:'white'}}
              onClick={()=>history.push('/comercio/' + offerModal.storeId ) }
            >Ir al Comercio</Button>
          </div>}

          { offerModal['offers.buttonText'] && offerModal['offers.buttonLink'] && <div style={{marginTop:'20px', textAlign:'center'}}>
            <a href={offerModal['offers.buttonLink']} target="_blank" rel="noreferrer">
              <Button size='large' className='standard-button' style={{backgroundColor:UI_COLOR_BUTTON, color:'white'}}>
                {offerModal['offers.buttonText']}
              </Button>
            </a>
          </div>}

        </div>}
      </Modal>

    </Main>
  )
}