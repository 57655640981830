import firebase from 'firebase/app'
import 'firebase/auth'

if (!firebase.apps.length) {
  firebase.initializeApp({


    apiKey: "AIzaSyB1V1UIG5ULDUlSBnWE4uaeW0gmR-iJM48",
    authDomain: "pretii-auth.firebaseapp.com",
    projectId: "pretii--auth",
  })
}

export default firebase