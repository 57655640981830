export const isMultiStore = false
export const platformName = 'cprofessional'
export const clienAppName = 'Club Professional'

export const loginPageLogo = require( './assets/Logo_elite.jpg' ).default
export const versionName = '4.4.2'

//for one store
export const clienStoreId = 1

// for PROD
export const API_SERVER = 'https://cprofessional.api.pretii.lat'

// for DEV
//export const API_SERVER = 'http://192.168.1.20:8080'


export const UI_COLOR_BUTTON = '#15bafe'
export const UI_LOGIN_FOOTER_OBJ = {
  text: 'Acumula puntos y gana premios',
  link: null
}

// Social login
export const LOGIN_SOCIAL = false
// Registration enabled
export const REGISTRATION_ENABLED = false

export const enableGA = false

export const APP_ANDROID = null
export const APP_IOS     = null
