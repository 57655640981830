import React, {useState, useEffect} from 'react'

import Main from '../components/Main'
import Footer from '../components/Footer'
import Header from '../components/Header'

import { useHistory } from 'react-router-dom'
import { Form, Input, Button, notification } from 'antd'

import { UI_COLOR_BUTTON } from '../config'
import { REST_PROCESS_PASS_RECOVER, requestToServer, clearLocalData } from '../lib/helper'

export default function Password() {

  const urlParams = new URLSearchParams(window.location.search)
  let token = urlParams.get('token')
  
  let history = useHistory()

  const [password1, setPassword1] = useState(null)
  const [password2, setPassword2] = useState(null)

  useEffect(() => clearLocalData(), [])

  const changePassword = () => {
    if ( password1 && password2) {
      if ( password1 === password2 ) {
        requestToServer({token,password: password1}, REST_PROCESS_PASS_RECOVER).then(result=>{
          if (result.s === 'ok') {
            notification.success({message:'Contraseña restablecida.'})
            history.push('/')
          } else { notification.error({message:'No se puede restablecer la contraseña.', description:result.m}) }
        })
      } else { notification.error({message:'Las contraseñas deben ser iguales.'}) }
    } else {
      notification.error({message:'Tiene que imgresar una nueva contraseña.'})
    }
  }

  return (
    <Main>
      <Header title='Cambiar la contraseña' backLink='/' />
      <div style={{paddingLeft:'15px', paddingRight:'15px', paddingTop:'20px'}}>
        <Form
          layout='vertical'
          style={{maxWidth:'200px', margin:'0 auto'}}
        >
          <Form.Item label="Ingresa una contraseña">
            <Input.Password  value={password1} onChange={(e)=>setPassword1(e.target.value)}/>
          </Form.Item>
          <Form.Item label="Repite la contraseña">
            <Input.Password  value={password2} onChange={(e)=>setPassword2(e.target.value)}/>
          </Form.Item>


        </Form>

        <div style={{textAlign:'center', paddingBottom:'40px'}}>
          <Button className='' style={{backgroundColor:UI_COLOR_BUTTON,  color:'white'}} size='large'
            onClick={ ()=>changePassword() }
          >
            Establecer contraseña
          </Button>
        </div>

      </div>
      <Footer />
    </Main>
  )
}