import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { notification, Input, Button } from 'antd'
import { CircleSlider } from 'react-circle-slider'

import Main from '../components/Main'
import Footer from '../components/Footer'
import Header from '../components/Header'

import {
  REST_SAVE_SURVEY,
  REST_VALIDATE_SURVEY,
  requestToServer
} from '../lib/helper'
import { UI_COLOR_BUTTON } from '../config'

export default function Survey() {

  const urlParams = new URLSearchParams(window.location.search)
  let token = urlParams.get('token')
  let history = useHistory()

  const [loading, setLoading] = useState(true)
  const [score, setScore] = useState(0)
  const [comment, setComment] = useState(null)
  const [color, setColor] = useState('#e43e3d')
  const [store, setStore] = useState(null)
  const [hasScored, setHasScored] = useState(false)

  useEffect(() => {
    requestToServer({token}, REST_VALIDATE_SURVEY).then(result=> {
      if (result.s === 'ok' && result.store ) {
        setStore(result.store)
        setLoading(false)

      } else {
        history.push('/')
        notification.error({message:'La encuesta ya fué resuelta anteriormente.'})
      }

    })
  }, [])


  const sendSurvey = () => {
    if (hasScored) {
      notification.success({message:'Gracias por la encuesta.'})
      history.push('/')
      requestToServer({token, score, comment}, REST_SAVE_SURVEY).then(result=> {})
    } else {
      notification.error({message:'Califique de 0 a 10', description:'Use el selector circular para realilzar una calificación.'})
    }
  }

  
  return(
    <Main>
      <Header title='Encuesta' backLink='/' />
      { loading ? <div style={{padding:'15px'}}>Por favor espere miestras revisamos que la encuesta no haya sido resuelta anteriormente</div> :
        <div style={{paddingLeft:'15px', paddingRight:'15px'}}>
          { store && store.logo && <div style={{textAlign:'center'}}><img src={store.logo} style={{height:'80px', width:'auto'}}/></div>}
          <h2 style={{textAlign:'center'}}>{store.name}</h2>
          <h3>Qué tan probable es que nos recomiendes a un familiar o amigo?</h3>

          <div style={{textAlign:'center', paddingBottom:'20px', position:'relative'}} >
            <div style={{
              position: 'absolute',
              top:'150px',
              textAlign:'center',
              color: '#e43e3d',
              fontWeight: 'bold',
              right: '65%'
            }}>Nada Probable</div>
            <div style={{
              position: 'absolute',
              top:'150px',
              textAlign:'center',
              color: '#5aaf2b',
              fontWeight: 'bold',
              lineHeight:'100%',
              left: '65%'
            }}>Extremadamente <br/>Probable</div>
            <CircleSlider value={score} onChange={value => {
              setScore(value)
              setHasScored(true)
              switch (value) {
                case 0 :
                  setColor('#e43e3d')
                  break;
                case 1 :
                  setColor('#ea484d')
                  break;
                case 2 :
                  setColor('#ec654e')
                  break;
                case 3 :
                  setColor('#ef874c')
                  break;
                case 4 :
                  setColor('#f3a74c')
                  break;
                case 5 :
                  setColor('#f8c43d')
                  break;
                case 6 :
                  setColor('#e1c63b')
                  break;
                case 7 :
                  setColor('#c1cc36')
                  break;
                case 8 :
                  setColor('#9fcd35')
                  break;
                case 9 :
                  setColor('#7fcd31')
                  break;
                case 10 :
                  setColor('#5aaf2b')
                  break;
              }
              
            }} showTooltip={true}
              min={0}
              max={10}
              progressColor={color}
              tooltipColor={color}

            />
          </div>

          <h3>Déjamos un comentario:</h3>
          <Input.TextArea autoSize={true}
            onChange={ event => setComment( event.target.value ) }
            value={ comment } 
          />

          <div style={{textAlign:'center', paddingTop:'20px'}}>
            <Button size='large' style={{backgroundColor:UI_COLOR_BUTTON, borderRadius:'5px', paddingLeft:'40px', paddingRight:'40px', color:'white'}}
              onClick={()=>sendSurvey()}
            >Enviar</Button>
          </div>
        </div>
      }
      <Footer />
    </Main>
  )
}