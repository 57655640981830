import React from 'react'
import { APP_ANDROID, APP_IOS, clienAppName } from '../config'

const androidButton = require('../assets/general/play-store-button.png').default
const iosButton = require('../assets/general/app-store-button.png').default

const AppButtons = () => {
  let showButtons = APP_ANDROID || APP_IOS ? true : false

  return (
    <>
    { showButtons && <div className='app-mobile-buttons-container'>
      <h2>Descarga la app <strong>{clienAppName}</strong>:</h2>

      <div style={{textAlign:'center'}}>      

        { APP_ANDROID && <a href={APP_ANDROID} className='app-link' target="_blank">
          <img alt={' Botón de Google Play Store para ' + clienAppName } src={androidButton}  className='app-mobile-buttons'/>
        </a>} 

        { APP_IOS && <a href={APP_IOS} className='app-link' target="_blank">
          <img alt={' Botón de Apple App Store para ' + clienAppName } src={iosButton} className='app-mobile-buttons'/>
        </a>} 

      </div>

    </div> }
    </>
  )
}

export default AppButtons