import React from 'react'
import { useHistory } from 'react-router-dom'

import {PageHeader} from 'antd'

const Header = ({ backLink, title }) => {

  let history = useHistory()

  let backFunction = backLink ? () => {history.push(backLink)} : null

  return (
    <PageHeader
      title={title}
      onBack={backFunction}
    />
  )
}

export default Header