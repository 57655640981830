import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'
import QRCode from 'react-qr-code'

import Main from '../components/Main'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { platformName } from '../config'
import { getLocalData } from '../lib/helper'

export default function MyQr() {

  let history = useHistory()

  const [qrCode, setQrCode] = useState('')

  useEffect(() => {
    let localData = getLocalData()
    if (localData) {
      setQrCode(localData.user.qrcode)
    } else { history.push('/') }
  }, [])


  return (
    <Main>
      <Header title='Mi QR' backLink='/' />
      <div style={{padding:'20px'}}>
        <div style={{textAlign:'center', paddingBottom:'30px'}}>
          <QRCode value={qrCode} />
        </div>
        <div style={{textAlign:'center', fontSize:'2em', fontWeight:'bold', paddingBottom:'10px'}}>{qrCode}</div>
        { platformName === 'pretii' ? 
          <div>Muestra este código en los negocios que utilizan la plataforma Pretii y acumula puntos !</div>
        :
          <div>Muestra este código y acumula puntos !</div>
        }
      </div>
      <Footer />
    </Main>
  )
}