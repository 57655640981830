import React, { useReducer, createContext } from "react";

export const GeneralContext = createContext();

const initialState = {
  userStores:[], // stores that the user belongs with all points
  profile: null,
  socialLogin : null,
  offers : null
}

const reducer = (state, action) => {
  switch (action.type) {

    case 'SET_USER_STORES':
      return { ...state, userStores: action.payload }

    case 'SET_PROFILE':
      return { ...state, profile: action.payload }

    case 'SET_SOCIAL_LOGIN':
      return { ...state, socialLogin: action.payload }

    case 'SET_OFFERS':
      return { ...state, offers: action.payload }

    case 'CLEAR_STATE':
      return initialState

    default:
      throw new Error();
  }
};

export const ContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GeneralContext.Provider value={[state, dispatch]}>
      {props.children}
    </GeneralContext.Provider>
  );
};
