import React from 'react'
import { clienAppName, platformName } from '../config'

const Footer = () => {
  return (
    <div style={{textAlign:'center', backgroundColor:'#cccccc', color:'black',  padding:'10px', marginTop:'20px'}}>
      <p>Acumula puntos y gana premios con <strong>{clienAppName}</strong></p>
      { platformName === 'pretii' && <p>Si tienes un comercio <a href="https://pretii.lat/registro" target="_blank">regístralo</a></p> }
    </div>
  )
}

export default Footer