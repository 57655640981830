import React from 'react'
import { Switch, Router, Route } from 'react-router-dom'
import importedComponent from 'react-imported-component'
//import ReactGA from 'react-ga'

import Home from '../pages/Home'
import Comerces from '../pages/Comerces'
import Store from '../pages/Store'
import MyQr from '../pages/MyQr'
import Profile from '../pages/Profile'
import Register from '../pages/Register'
import Confirm from '../pages/Confirm'
import Password from '../pages/Password'
import Survey from '../pages/Survey'

import {enableGA} from '../config'


import Loading from './Loading'

import { ContextProvider } from '../lib/context'

import { createBrowserHistory } from 'history'


const AsyncDynamicPAge = importedComponent(
  () => import(/* webpackChunkName:'DynamicPage' */ './DynamicPage'),
  {
    LoadingComponent: Loading
  }
)
const AsyncNoMatch = importedComponent(
  () => import(/* webpackChunkName:'NoMatch' */ './NoMatch'),
  {
    LoadingComponent: Loading
  }
)

//ReactGA.initialize('UA-51713860-13');
var history = createBrowserHistory()
if (enableGA) {
//  ReactGA.pageview(history.location.pathname)
}
history.listen(location => {
  if (enableGA) {
//    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  }
})

const App = () => {
  return (
        <ContextProvider>

    <Router history={history}>

        <Switch>

          <Route exact path="/" >
            <Home />
          </Route>

          <Route exact path="/comercios" >
            <Comerces />
          </Route>

          <Route exact path="/comercio/:storeId" >
            <Store />
          </Route>

          <Route exact path="/mi-qr" >
            <MyQr />
          </Route>

          <Route exact path="/mi-perfil" >
            <Profile />
          </Route>

          <Route exact path="/registro" >
            <Register />
          </Route>

          <Route exact path="/confirmar-registro/:token" >
            <Confirm />
          </Route>

          <Route exact path="/password" >
            <Password />
          </Route>

          <Route exact path="/encuesta" >
            <Survey />
          </Route>

          <Route exact path="/dynamic" component={AsyncDynamicPAge} />
          <Route component={AsyncNoMatch} />


        </Switch>

    </Router>
        </ContextProvider>
  )
}

export default App
